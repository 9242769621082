
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { GrupoProdutoService, TipoProdutoService } from "@/core/services/almoxarifado";

@Component
export default class CfgRequisicao extends Vue {
  item: {
    id: number;
    grupoId: number;
    tipoId: number;
    codigo: string;
    nome:string;
  } = {
    id: 0,
    grupoId: 0,
    tipoId: 0,
    codigo: "",
    nome:""
  };

  valid = true;
 
  grupos =  [];
  tipos = [];

  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório de Estoque', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  mounted() {
    const gruposProdutoService = new GrupoProdutoService();
    gruposProdutoService.ListarTudo().then(
      (res) => {
        this.grupos = res.data.items;
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );

    const tipoProdutoService = new TipoProdutoService();
    tipoProdutoService.ListarTudo().then(
      (res) => {
        this.tipos = res.data.items;
      },
      (err) => this.$swal("Aviso", err.message, "error")
    );
  }
 
  Visualizar() {
    let routeLink = this.$router.resolve({name: 'relEstoque',
      query:{
        codigo: this.item.codigo,
        nome: this.item.nome,
        tipoId: this.item.tipoId ?  this.item.tipoId.toString() : '',
        grupoId: this.item.grupoId ?  this.item.grupoId.toString() : '',
      }
    });
    window.open(routeLink.href, '_blank');
  }
}
